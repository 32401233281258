import React from 'react';

import { useConfiguration } from '../../context/configurationContext';

const PerUnit = props => {
  const config = useConfiguration();
  const { listing } = props;
  const { publicData } = listing?.attributes;
  const listingConfig = config.listing;

  if (!publicData || !listingConfig) {
    return null;
  }

  const { listingFields } = listingConfig || {};
  const { unit } = listing?.attributes?.publicData || {};
  const unitField = listingFields.find(item => item.key === 'unit');
  const enumOptions = unitField?.enumOptions || [];
  const result = enumOptions.find(item => item.option === unit)?.label;

  return <>{result}</>;
};

export default PerUnit;
